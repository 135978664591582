import videojs from 'video.js'
import { upnextSpinnerAdvanced, upnextSpinner } from '../utils/svgs'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

/**
 * Related Item UpNext Countdown Container
 *
 * @extends Component
 * @class UpNextCountdownContainer
 */
class UpNextCountdownContainer extends Component {
  constructor (player, options) {
    options = videojs.obj.merge({
      countdownDuration: 10
    }, options)
    super(player, options)

    this.timeoutId = null
    this.intervalId = null

    this.startRedirectTimer = this.startRedirectTimer.bind(this)
    this.on(player, 'adstart', () => {
      this.off(this.player_, 'ended', this.startRedirectTimer)
    })

    this.on(player, 'adend', () => {
      this.on(this.player_, 'ended', this.startRedirectTimer)
    })

    this.on(player, 'ended', this.startRedirectTimer)
    this.on(player, 'play', this.stopRedirectTimer.bind(this))
    this.on(player, 'stopAutoRedirect', this.stopAutoRedirect.bind(this))
    this.on(this.stopRedirectButton, 'click', this.stopAutoRedirect)
    this.on(this.upNextCountdownSpinner, ['tap', 'click'], this.playNext)
    this.on(this.playRedirectButton, 'click', this.playNext)
    this.on(player, 'loadstart', this.addCloseListener)
    player.addClass('agnoplayer-upnext-available')
  }

  addCloseListener () {
    this.on(this.player().getChild('PlayerRelatedItemsContainer').getChild('CloseButton'), 'relateditemsclosed', this.stopAutoRedirect)
  }

  stopAutoRedirect () {
    this.stopRedirectButton.textContent = this.player().localize('Next')
    Dom.addClass(this.upNextCountdownSpinner, 'vjs-hidden')
    Dom.addClass(this.countdownTimeEl, 'vjs-hidden')
    Dom.removeClass(this.playRedirectButton, 'vjs-hidden')
    this.stopRedirectTimer()
  }

  startRedirectTimer () {
    const options = this.options_

    if (options.countdownDuration === 0) {
      this.playNext()
    } else {
      this.show()
      this.stopRedirectTimer()
      this.timeoutId = this.setTimeout(this.playNext, options.countdownDuration * 1000)
      let countdownTime = options.countdownDuration
      this.countdownTimeEl.innerHTML = countdownTime
      this.intervalId = this.setInterval(() => {
        if (countdownTime > 0) {
          countdownTime--
          this.countdownTimeEl.innerHTML = countdownTime
        } else {
          this.clearInterval()
        }
      }, 1000)
    }
  }

  stopRedirectTimer () {
    if (typeof this.timeoutId !== 'undefined') {
      this.clearTimeout(this.timeoutId)
    }
    if (typeof this.intervalId !== 'undefined') {
      this.clearInterval(this.intervalId)
    }
    this.timeoutId = null
    this.intervalId = null
  }

  playNextVideoId () {
    const {
      videoId,
      articleId,
      playerId
    } = this.options_

    window.AGNO.updatePlayerConfig(playerId, {
      ...window.AGNO.Players.getPlayerById(playerId).config,
      videoId,
      articleId,
      autoplay: true
    })
  }

  redirect () {
    window.location = this.options_.link
  }

  playNext () {
    if (this.options_.upnextRedirect) {
      this.redirect()
    } else {
      this.playNextVideoId()
    }
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    const el = super.createEl(tag, props, attributes)

    const upnextCountdownContent = Dom.createEl('div', {
      className: 'upnext-countdown-content'
    })
    Dom.insertContent(el, upnextCountdownContent)

    this.stopRedirectButton = Dom.createEl('div', {
      textContent: this.player().localize('Stop'),
      className: 'upnext-countdown-stop'
    })
    Dom.insertContent(upnextCountdownContent, this.stopRedirectButton)

    this.playRedirectButton = Dom.createEl('div', {
      textContent: '',
      className: 'upnext-countdown-play vjs-hidden'
    })
    Dom.appendContent(upnextCountdownContent, this.playRedirectButton)

    this.upNextCountdownSpinner = Dom.createEl('div', {
      innerHTML: this.player().hasAdvancedTheme() || this.options().countdownDuration > 0 ? upnextSpinnerAdvanced : upnextSpinner,
      className: 'upnext-countdown-spinner spinner-outer'
    })
    this.upNextCountdownSpinner.querySelector('.upnext-play-svg-circle').style.animationDuration = `${this.options().countdownDuration}s`
    this.countdownTimeEl = Dom.createEl('div', {
      className: 'agnoplayer-countdown-spinner-time'
    })
    Dom.appendContent(upnextCountdownContent, this.upNextCountdownSpinner)
    Dom.appendContent(this.upNextCountdownSpinner, this.countdownTimeEl)

    return el
  }

  buildCSSClass () {
    return 'upnext-countdown-container'
  }

  dispose () {
    this.stopRedirectTimer()
    this.off(this.player(), 'ended', this.startRedirectTimer.bind(this))
    this.off(this.player(), 'play', this.stopRedirectTimer.bind(this))
    this.off(this.player(), 'stopAutoRedirect', this.stopAutoRedirect.bind(this))
    this.off(this.stopRedirectButton, 'click', this.stopAutoRedirect)
    this.off(this.upNextCountdownSpinner, ['tap', 'click'], this.playNext)
    this.off(this.playRedirectButton, 'click', this.playNext)
    this.off(this.player(), 'loadstart', this.addCloseListener)
    super.dispose()
  }
}

Component.registerComponent('UpNextCountdownContainer', UpNextCountdownContainer)
export default UpNextCountdownContainer
